//
// Page-title
//

.page-title-box {
  padding-bottom: $grid-gutter-width;

  .breadcrumb {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0;

    &-item {
      line-height: 18px;
    }
  }
  h3 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
  }
}
