/* You can add global styles to this file, and also import other style files */

@import "assets/scss/variables";
@import "assets/scss/ag-agent-variables";
// @import "ag-variables";
// @import "node_modules/bootstrap/scss/bootstrap";
// @import "ag-agent-variables";
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
html {
  margin: 0;
  min-height: calc(100% + env(safe-area-inset-top));
  height: 100%;
}

html {
  margin: 0;
  height: 100%;
}

.toast-container,
.toast-container-paddings {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.ag-portal {
  &__loading-indication-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
  }

  &__loading-indication-icon {
    display: block;
    $size: calc(512px / (3));
    width: $size;
    height: $size;
    filter: drop-shadow(3px 1px 4px #333);
    animation: blink 2s infinite;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: $h1-font-size;
}
h2 {
  font-size: $h2-font-size;
}
h3 {
  font-size: $h3-font-size;
}
h4 {
  font-size: $h4-font-size;
}
h5 {
  font-size: $h5-font-size;
}
h6 {
  font-size: $h6-font-size;
}

.dropdown-toggle {
  &::after {
    display: none;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
