.ag-editor {
  height: 100%;

  .dx-button-has-icon .dx-icon {
    display: flex !important;
    align-items: center !important;
    width: 14px;
    height: 12px !important;
    background-position: 0 0;
    background-size: 44px 44px;
    font-size: 15px;
    border: none;
  }

  .dx-context-menu.user-menu {
    &.dx-rtl {
      .dx-submenu .dx-menu-items-container .dx-icon {
        margin-left: 16px;
      }
    }
    .dx-submenu .dx-menu-items-container .dx-icon {
      margin-right: 16px;
    }
    .dx-menu-item .dx-menu-item-content {
      padding: 3px 15px 4px;
    }
  }

  .dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
    padding-left: 4px;
    padding-right: 4px;
  }

  .editToolbar {
    .dx-toolbar {
      .dx-toolbar-items-container {
        height: 36px;
        overflow: visible;
        border: 2px solid lightgray;
      }
    }
  }

  .toolbar-label,
  .toolbar-label > b {
    font-size: 16px;
  }

  .dx-treelist .dx-treelist-content .dx-treelist-table .dx-row .dx-command-edit-with-icons .dx-link {
    color: rgba(9, 29, 69, 0.75);
  }

  .widget-container > span {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .editor {
    &__data-grid {
      box-shadow: none;

      &--view {
        padding: 0 15px;

        .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
          color: #333;
          font: 14px/1 DXIcons;
          opacity: 1;
          border: 0;
        }
      }

      &--empty {
        color: #999;
        font-size: 17px;
        text-align: center;
        margin-bottom: 0;
      }
    }

    &__data-grid--empty {
      color: #999;
      font-size: 16px;
      text-align: center;
      margin-bottom: 0;
    }
  }
}

// for editor without grid
.editor-empty-text {
  display: block;
  color: #999;
  font-size: 17px;
  text-align: center;
  padding-bottom: 18px;
  padding-top: 30px;
}
