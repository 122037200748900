//
// _footer.scss
//

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    color: $footer-color;
    height: $footer-height;
    box-shadow: $box-shadow;
    background-color: $footer-bg;
}

