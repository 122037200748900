.dx-item:has(> .dx-item-content > .min-width-min-content) {
  min-width: min-content !important;
}

.dx-flex-layout:has(> .dx-field-item-content > .dx-swipeable) {
  margin-top: 7.5px;
}

.dx-field-item-help-text {
  padding-left: 8px;
}

.dx-list.dx-state-disabled {
  opacity: 1;
  .dx-list-item {
    opacity: 1;
  }
}

.ag-portal-grid {
  box-shadow: none;

  tr:nth-child(even) {
    background-color: initial;
  }

  .dx-datagrid-borders > .dx-datagrid-header-panel {
    border-bottom: 0;
  }

  .dx-datagrid-header-panel {
    padding: 0 0 8px 0;
    border-bottom: 2px solid lightgrey;

    .dx-toolbar {
      margin-bottom: 0;
    }
  }

  &--view {
    padding: 0 15px;

    .dx-datagrid-header-panel {
      padding: 0;
    }

    .dx-datagrid-headers {
      font-size: 14px;
      font-weight: 700;
      color: #333;
      background-color: transparent;
    }

    .dx-datagrid-rowsview,
    .dx-datagrid-headers {
      border: none;
      .dx-header-row {
        td {
          border: none;
        }
      }
    }
  }
}

.dx-tabs-wrapper {
  &::after {
    display: none;
  }
}