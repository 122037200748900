// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Alliance_Group-primary: mat.define-palette(mat.$indigo-palette);
$Alliance_Group-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Alliance_Group-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Alliance_Group-theme: mat.define-light-theme(
  (
    color: (
      primary: $Alliance_Group-primary,
      accent: $Alliance_Group-accent,
      warn: $Alliance_Group-warn
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Alliance_Group-theme);

/*
Template Name: Nazox - Responsive Bootstrap 4 Admin Dashboard
Author: Themesdesign
Contact: themesdesign.in@gmail.com
File: Custom Bootstrap Css File
*/
// @import "angular-material";

//Core files
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "ag-variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "ag-agent-variables";

@import "custom/components/backgrounds";
@import "custom/components/badge";
@import "custom/components/buttons";
@import "custom/components/breadcrumb";
@import "custom/components/card";
@import "custom/components/dropdown";
@import "custom/components/nav";
@import "custom/components/table";
@import "custom/components/pagination";
@import "custom/components/progress";

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@media (min-width: 576px) {
  .row.row-cols-1 {
    .card {
      margin-bottom: 0;
    }
  }
}
